<template>
  <div>
    <v-row>
      <v-col
        v-for="project in projectList"
        :key="project.id"
        md="4"
        sm="6"
        cols="12"
      >
        <project-card
          :project="project"
          @viewproject="viewDetails"
        ></project-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>

import {
  mdiChevronUp,
  mdiChevronDown,
  mdiCartPlus,
  mdiShareVariantOutline,
  mdiLockOpenOutline,
  mdiStarOutline,
  mdiAccountOutline,
  mdiTrendingUp,
  mdiHelpCircleOutline,
} from '@mdi/js'

import { ref } from '@vue/composition-api'
import projects from '@/library/project'
import ProjectCard from './ProjectCard.vue'

export default {

  components: {
    ProjectCard,
  },

  setup(props, context) {
    const isCardDetailsVisible = false
    const router = context.root.$router
    const route = context.root.$route

    const viewDetails = function viewDetails(id) {
      router.push({
        name: 'projects-view',
        query: {
          ...route.query,
        },
        params: {
          id,
        },
      })
    }

    const projectList = ref([])

    const loadProjectList = async () => {
      projectList.value = await projects.list()
    }

    loadProjectList()

    return {
      isCardDetailsVisible,
      viewDetails,
      projectList,

      icons: {
        mdiChevronUp,
        mdiChevronDown,
        mdiCartPlus,
        mdiShareVariantOutline,
        mdiLockOpenOutline,
        mdiStarOutline,
        mdiAccountOutline,
        mdiTrendingUp,
        mdiHelpCircleOutline,
      },
    }
  },
}
</script>
