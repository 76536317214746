<template>
  <div>
    <v-card>
      <v-img
        :src="coverImage"
        height="250"
      ></v-img>
      <v-card-title>
        {{ title }}
      </v-card-title>
      <v-card-text>
        {{ summary }}
      </v-card-text>
      <v-card-actions class="dense">
        <v-btn
          color="primary"
          text
          @click="viewProject"
        >
          Details
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn
          icon
          @click="isCardDetailsVisible = !isCardDetailsVisible"
        >
          <v-icon>{{ isCardDetailsVisible ? icons.mdiChevronUp : icons.mdiChevronDown }}</v-icon>
        </v-btn>
      </v-card-actions>
      <v-expand-transition>
        <div v-show="isCardDetailsVisible">
          <v-divider></v-divider>
          <v-card-text>
            {{ description }}
          </v-card-text>
        </div>
      </v-expand-transition>
    </v-card>
  </div>
</template>

<script>
import { ref } from '@vue/composition-api'
import media from '@/library/media'
import {
  mdiChevronUp,
  mdiChevronDown,
  mdiCartPlus,
  mdiShareVariantOutline,
  mdiLockOpenOutline,
  mdiStarOutline,
  mdiAccountOutline,
  mdiTrendingUp,
  mdiHelpCircleOutline,
} from '@mdi/js'

export default {
  props: {
    project: {
      type: Object,
      required: true,
    },
  },
  setup(props, { emit }) {
    const {
      title, id, description, cover,
    } = props.project
    const isCardDetailsVisible = false
    const viewProject = function viewProject() {
      emit('viewproject', id)
    }
    const summary = description.substring(0, 45)
    const coverImage = ref('')
    const loadCover = async () => {
      if (cover != null) {
        coverImage.value = await media.getImageObjectUrl(cover)
      }
    }

    loadCover()

    return {
      title,
      id,
      summary,
      description,
      viewProject,
      isCardDetailsVisible,
      coverImage,

      icons: {
        mdiChevronUp,
        mdiChevronDown,
        mdiCartPlus,
        mdiShareVariantOutline,
        mdiLockOpenOutline,
        mdiStarOutline,
        mdiAccountOutline,
        mdiTrendingUp,
        mdiHelpCircleOutline,
      },
    }
  },
}
</script>
