<template>
  <div>
    <v-row>
      <v-col
        cols="12"
        md="9"
      >
        <p class="text-2xl mb-6">
          My Projects
        </p>
      </v-col>
      <v-col
        cols="12"
        md="3"
      >
        <v-btn
          color="primary"
          @click="createProject"
        >
          Create Project
        </v-btn>
      </v-col>
    </v-row>

    <project-list></project-list>
  </div>
</template>

<script>
import ProjectList from './ProjectList.vue'

export default {
  components: {
    ProjectList,
  },

  setup(props, context) {
    const router = context.root.$router
    const route = context.root.$route

    const createProject = function createProject() {
      router.push({
        name: 'projects-add',
        query: {
          ...route.query,
        },
        params: {},
      })
    }

    return {
      createProject,
    }
  },
}

</script>
